import { useState } from 'react';
import { getDetails, getGeocode, SetValue } from 'use-places-autocomplete';

interface FieldNames {
  [key: string]: string;
}

const useAddressAutocomplete = (
  setValue: (
    name: string,
    value: string | undefined,
    options?: { shouldValidate: boolean }
  ) => void,
  setAutocompleteValue: SetValue,
  fieldNames: FieldNames
): {
  handleSelect: (description: string) => Promise<void>;
  description: string;
  streetValue: string;
} => {
  const [description, setDescription] = useState<string>('');
  const [streetValue, setStreetValue] = useState<string>('');

  const handleSelect = async (description: string) => {
    setDescription(description);

    const results = await getGeocode({ address: description });
    const details = await getDetails({
      placeId: results[0].place_id,
      fields: ['address_components'],
    });
    const addressComponents = details.address_components;

    Object.keys(fieldNames).forEach((fieldName) => {
      const component = addressComponents.find((comp: any) =>
        comp.types.includes(fieldNames[fieldName])
      )?.long_name;

      setValue(fieldName, component, { shouldValidate: true });
      if (fieldName === 'street') setStreetValue(component);
      if (!component) setValue(fieldName, '', { shouldValidate: true });
    });

    setAutocompleteValue('');
  };

  return { handleSelect, description, streetValue };
};

export default useAddressAutocomplete;
